input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator, input[type="datetime-local"]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    border-radius: 4px;
    margin-right: 2px;
    opacity: 0.9;
    filter: invert(0.9);
}
.arriveDate, .arriveTime  {
    -webkit-appearance: none;
    -moz-appearance: none;
}
input::-webkit-datetime-edit {
    display: block;
    padding: 0;
}